<template>
    <v-dialog
        v-model="dialog"
        max-width="500"
        persistent
    >
        <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{nombre}}
                </v-card-title>
                <v-card-text class="pt-2">
                    <!-- ---------------------------------------------------- -->
                    <v-radio-group
                        v-model="valor"
                        row
                    >
                        <v-radio
                            label="Mejorado"
                            value=1
                        />
                        <v-radio
                            label="Delicado"
                            value=2
                        />
                        <v-radio
                            label="Grave"
                            value=3
                        />
                        <v-radio
                            label="Muy grave"
                            value=4
                        />
                   </v-radio-group>

                   <v-text-field
                        v-model="observaciones"
                        filled
                        rounded
                        clearable
                        dense
                        label="Observaciones"
                    ></v-text-field>
                    

                    <!-- ---------------------------------------------------- -->
                </v-card-text>

                <v-card-actions>
                    
                    <v-btn
                        :disabled="!valor"
                        color="success"
                        :loading="loading"
                        @click="agrega"
                    >
                        Agregar
                    </v-btn>
                    
                    <v-btn color="error" @click="cancela">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogDe:Boolean,
        turno:String,
        nombre:String,

        estaFecha:String,
        internamiento:Number

    },

    data:()=>({
        loading:false,
        valor:null,
        observaciones:'',

    }),

    computed:{
        dialog:{
            get(){
                return this.dialogDe
            },
            set(val){
                this.$emit('update:dialogDe',val)
            },
        }
    },
    methods:{
        cancela(){
            this.dialog = false
        },
        async agrega(){
            //console.log(v)
            console.log ('Actualizando VALGRAL en en turno '+this.turno+' con los valores '+this.valor)
            this.dialog = false


            this.loading=true

            let datos = {
                fecha: this.estaFecha,
                turno: this.turno,
                escala: 'general',
                valor: this.valor,
                internamiento:this.internamiento,
                observaciones: this.observaciones,
            }
            try{
                let dt = await this.$http({
                    url:'/enfermeria/guardaEscalas',
                    method: 'POST',
                    data: datos
                })
                if (dt.data.estado == true){
                    this.$emit('escalaSuccess')
                    this.observaciones = ''
                    this.valor = 0
                    this.loading = false
                    this.dialog = false
                } else {
                    console.log(dt.data)
                    this.loading = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }
            
        },
    },
}
</script>

<style>

</style>